import { HTMLAttributes } from 'react'
import Button from '../../Button'
import RichText from '../../RichText'
import Typography from '../../Typography'
import {
  Attributes,
  CallToAction,
  Card,
  Container,
  Header,
  Subheader,
} from './PricingCard.style'

export interface PricingCardProps extends HTMLAttributes<HTMLDivElement> {
  id: string
  title: string
  description: string
  buttonLabel: string
  attributes: string
  onClick?: () => void
  active?: boolean
}

const PricingCard = ({
  id,
  title,
  description,
  attributes,
  buttonLabel,
  onClick,
  active,
  ...props
}: PricingCardProps) => {
  const [name, ...subtitleParts] = title.split(' ')
  const subtitle = subtitleParts.join(' ')
  return (
    <Container {...props}>
      <Card $active={active} onClick={onClick}>
        <Header>
          <div>
            <Typography variant="h3" align="left" color="inherit">
              {name}
            </Typography>
            {subtitle && (
              <Typography variant="body2" align="left" color="inherit">
                {subtitle}
              </Typography>
            )}
          </div>
        </Header>
        <Subheader>
          <Typography variant="caption" align="left" color="inherit">
            {description}
          </Typography>
          <CallToAction>
            <Button
              variant="filled"
              color={active ? 'secondary' : 'primary'}
              onClick={onClick}
            >
              {buttonLabel}
            </Button>
          </CallToAction>
        </Subheader>
        <Attributes>
          <RichText
            content={attributes}
            typographyProps={{
              variant: 'body2',
              align: 'left',
              color: 'inherit',
            }}
          />
        </Attributes>
      </Card>
    </Container>
  )
}

export default PricingCard
